import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ children, location, description, title, image}) {
  return (
    <Helmet>
      <html lang="de" />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>

      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
			<link rel="alternate icon" href="/favicon.ico" />
			<link rel="apple-touch-icon" href="/apple-touch-icon-192.png"></link>

      <meta name="description" content={description} />

    </Helmet>
  )
}
